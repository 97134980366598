/* angular:styles/component:css;98a4250953b23304c680235c6798eaeddac9182cdf0727f4be9b7de5476e1cff;/home/runner/work/tao/tao/ui/src/app/auth/components/logout-prompt/logout-prompt.component.ts */
:host {
  display: block;
  width: 100%;
  max-width: 300px;
}
mat-dialog-actions {
  display: flex;
  justify-content: flex-end;
}
[mat-button] {
  padding: 0;
}
/*# sourceMappingURL=logout-prompt.component.css.map */
