<mat-card appearance="outlined">
  <mat-card-title>
    <div class="header mat-elevation-z6">
      <div class="center-title">Password Reset</div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="form">
      <p>
        <mat-form-field>
          <input
            type="email"
            matInput
            placeholder="Email"
            formControlName="username"
          />
          <mat-error *ngIf="form.get('username').hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="form.get('username').hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </p>
      <div class="button">
        <button
          mat-raised-button
          [disabled]="form.invalid"
          (click)="onResetPassword()"
        >
          Send Request
        </button>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <a class="login-footer" routerLink="/login">
      Login
    </a>
  </mat-card-footer>
</mat-card>
