<mat-card appearance="outlined">
  <mat-card-title>
    <div class="header mat-elevation-z6">
      <div class="center-title">Reset Password</div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="myForm" (ngSubmit)="onResetPassword(password)">
      <p>
        <mat-form-field>
          <input
            matInput
            [type]="hideNew ? 'password' : 'text'"
            placeholder="New password"
            formControlName="password"
            required
          />
          <mat-icon matSuffix (click)="hideNew = !hideNew">{{
            hideNew ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <mat-error
            *ngIf="
              myForm.get('password').hasError('required') &&
              !myForm.get('password').hasError('minlength')
            "
          >
            Please enter a new password.
          </mat-error>
          <mat-error *ngIf="myForm.get('password').hasError('minlength')">
            Password must be at least 8 characters.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <input
            matInput
            [type]="hideConfirm ? 'password' : 'text'"
            placeholder="Confirm password"
            formControlName="passwordConfirm"
            [errorStateMatcher]="matcher"
            required
          />
          <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
            hideConfirm ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <mat-error *ngIf="myForm.hasError('notSame')">
            Passwords do not match
          </mat-error>
        </mat-form-field>
      </p>
      <div class="button">
        <button
          type="submit"
          mat-raised-button
          color="warn"
          [disabled]="myForm.invalid"
        >
          CHANGE PASSWORD
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
