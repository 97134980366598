import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './+state/configuration.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ConfigurationEffects } from './+state/configuration.effects';
import { ConfigurationFacade } from './+state';
import { first } from 'rxjs';
import { AuthModule } from '../auth/auth.module';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule,
    LayoutModule,
    StoreModule.forFeature('configurations', reducer),
    EffectsModule.forFeature([ConfigurationEffects]),
  ],
})
export class ConfigurationsModule {
  constructor(private configurations: ConfigurationFacade) {
    this.configurations.loaded$.pipe(first()).subscribe((loaded) => {
      if (!loaded) {
        this.configurations.loadConfigurations();
        this.configurations.loadAdminConfigurations();
      }
    });
  }
}
