import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as actions from './configuration.actions';
import { PortalConfiguration } from 'src/app/models';

export function sortByOrgName(
  a: PortalConfiguration,
  b: PortalConfiguration
): number {
  return a.name.localeCompare(b.name);
}

export const configurationsAdapter = createEntityAdapter({
  sortComparer: sortByOrgName,
});

export interface State extends EntityState<PortalConfiguration> {
  adminConfigurations: PortalConfiguration[];
  loaded: boolean;
}

export const initialState: State = configurationsAdapter.getInitialState({
  adminConfigurations: [],
  loaded: false,
});

export function reducer(
  state: State = initialState,
  action: actions.ConfigurationActionsUnion
) {
  switch (action.type) {
    case actions.ActionTypes.ConfigurationsLoaded: {
      return configurationsAdapter.setAll(action.payload, {
        ...state,
        loaded: true,
      });
    }

    case actions.ActionTypes.LoadAdminConfigurationsSuccess: {
      return { ...state, adminConfigurations: action.payload };
    }

    case actions.ActionTypes.ConfigurationsLoadError: {
      return { ...state, loaded: false, error: action.payload };
    }

    case actions.ActionTypes.ClearState: {
      return initialState;
    }

    default:
      return state;
  }
}

export const getLoaded = (state: State) => state.loaded;

// Create the default selectors
export const { selectIds, selectEntities, selectAll, selectTotal } =
  configurationsAdapter.getSelectors();

export const getAdminConfigurations = (state: State) =>
  state.adminConfigurations;
