<mat-card appearance="outlined">
  <mat-card-title>
    <div class="header mat-elevation-z6">
      <div class="center-title">Portal Login</div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="login(form.value)">
      <p>
        <mat-form-field>
          <input
            type="text"
            matInput
            autocomplete="off"
            placeholder="Email"
            formControlName="username"
          />
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <input
            matInput
            placeholder="Password"
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            autocomplete="off"
          />
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
            hidePassword ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </mat-form-field>
      </p>
      <div class="button">
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="form.invalid"
        >
          Login
        </button>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <a class="forgot-password" routerLink="/password-reset-request">
      Forgot Password
    </a>
  </mat-card-footer>
</mat-card>
