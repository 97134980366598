/* src/app/auth/components/login/login.component.scss */
:host {
  display: flex;
  justify-content: center;
  margin: 100px 0px;
}
.button {
  display: flex;
  justify-content: flex-end;
}
.header {
  display: flex;
  flex-direction: row;
  position: relative;
  top: -40px;
  height: 65px;
  align-self: center;
  align-content: center;
  border-radius: 5px;
  text-align: center;
}
.center-title {
  align-self: center;
  width: 100%;
}
.mat-mdc-card-title {
  height: 40px;
}
.mat-mdc-card-footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
  margin-right: 3px;
}
.mat-mdc-form-field {
  width: 100%;
  min-width: 300px;
}
.forgot-password {
  font-size: 0.7em;
  color: #c0c0c0;
  text-decoration: none;
  padding: 5px;
  margin: 5px;
}
/*# sourceMappingURL=login.component.css.map */
