import { Action } from '@ngrx/store';
import { PortalConfiguration } from 'src/app/models';

export enum ActionTypes {
  LoadConfigurations = '[Configuration] Load Configurations',
  ConfigurationsLoaded = '[Configuration] Configurations Loaded',
  ConfigurationsLoadError = '[Configuration] Configurations Load Error',

  LoadAdminConfigurations = '[Configuration] Load Admin Configurations',
  LoadAdminConfigurationsSuccess = '[Configuration] Load Admin Configurations Success',
  LoadAdminConfigurationsError = '[Configuration] Load Admin Configurations Error',

  ClearState = '[Configuration] Clear State',
}

export class LoadConfigurationsAction implements Action {
  readonly type = ActionTypes.LoadConfigurations;
}

export class ConfigurationsLoadedAction implements Action {
  readonly type = ActionTypes.ConfigurationsLoaded;
  constructor(public payload: PortalConfiguration[]) {}
}

export class ConfigurationsLoadErrorAction implements Action {
  readonly type = ActionTypes.ConfigurationsLoadError;
  constructor(public payload: string) {}
}

export class LoadAdminConfigurationsAction implements Action {
  readonly type = ActionTypes.LoadAdminConfigurations;
}

export class LoadAdminConfigurationsSuccessAction implements Action {
  readonly type = ActionTypes.LoadAdminConfigurationsSuccess;
  constructor(public payload: PortalConfiguration[]) {}
}

export class LoadAdminConfigurationsErrorAction implements Action {
  readonly type = ActionTypes.LoadAdminConfigurationsError;
  constructor(public payload: string) {}
}

export class ClearStateAction implements Action {
  readonly type = ActionTypes.ClearState;
}

export type ConfigurationActionsUnion =
  | LoadConfigurationsAction
  | ConfigurationsLoadedAction
  | ConfigurationsLoadErrorAction
  | LoadAdminConfigurationsAction
  | LoadAdminConfigurationsSuccessAction
  | LoadAdminConfigurationsErrorAction
  | ClearStateAction;
