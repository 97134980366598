import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromReducer from './configuration.reducer';
import * as fromSelectors from './configuration.selector';
import * as fromActions from './configuration.actions';
import { combineLatest, filter, map, Observable, withLatestFrom } from 'rxjs';
import { select } from '@ngrx/store';
import { PortalConfiguration } from 'src/app/models';
import { AuthFacade } from 'src/app/auth/+state';

@Injectable({ providedIn: 'root' })
export class ConfigurationFacade {
  constructor(
    private store: Store<fromReducer.State>,
    private auth: AuthFacade
  ) {}

  loaded$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectLoaded)
  );

  allConfigurations$: Observable<PortalConfiguration[]> = this.store.pipe(
    select(fromSelectors.selectAllConfigurations)
  );

  /**
   * Get all configurations that the user is an admin for
   */
  adminConfigurations$: Observable<PortalConfiguration[]> = this.store.pipe(
    select(fromSelectors.selectAdminConfigurations)
  );

  loadConfigurations() {
    this.store.dispatch(new fromActions.LoadConfigurationsAction());
  }

  loadAdminConfigurations() {
    this.store.dispatch(new fromActions.LoadAdminConfigurationsAction());
  }

  getConfigurationById(id: string): Observable<PortalConfiguration> {
    return this.store.pipe(select(fromSelectors.selectConfigurationById(id)));
  }
}
