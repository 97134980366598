import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppFacade } from '../../../+state';
import { AuthFacade } from '../../+state';
import { RouterFacade } from '../../../router/+state';
import { LoginCredential } from '../../../models';
import { LayoutFacade } from 'src/app/layout/+state';

@Component({
  selector: 'marrick-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  hidePassword = true;

  constructor(
    private auth: AuthFacade,
    private app: AppFacade,
    private router: RouterFacade,
    private fb: UntypedFormBuilder,
    private layout: LayoutFacade
  ) {}

  form: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(8)]],
  });

  login(credentials: LoginCredential) {
    // AR-535 Fully trim the password before attempting to login.
    const password = credentials.password.trim();
    this.auth.loginWithCredentials({
      username: credentials.username,
      password,
    });
  }

  ngAfterViewInit() {
    combineLatest([
      this.auth.authenticated$,
      this.auth.roles$,
      this.app.redirectUrl$,
    ])
      .pipe(
        filter(([auth, roles, redirectUrl]) => !!auth),
        takeUntil(this.destroy$)
      )
      .subscribe(([auth, roles, redirectUrl]) => {
        if (redirectUrl === '') {
          if (roles.includes('admin')) {
            this.router.go({ path: ['patients'] });
          } else {
            this.router.go({ path: ['patients'] });
          }
        } else {
          this.router.go({ path: [redirectUrl] });
        }
      });
    this.layout.flushLoading();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
