import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppFacade } from '../../../+state/app.facade';
import { AuthFacade } from '../../+state';
import { first } from 'rxjs/operators';
import { ResetPasswordRequest } from '../../../models';

@Component({
  selector: 'marrick-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent {
  form: FormGroup;
  constructor(
    private app: AppFacade,
    public auth: AuthFacade,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
    });
  }

  get username() {
    return this.form.get('username').value;
  }

  onResetPassword() {
    if (this.form.valid) {
      this.app.resetPasswordHTML$.pipe(first()).subscribe((html) => {
        const body: ResetPasswordRequest = {
          username: this.username,
          html: html,
        };
        this.auth.resetPasswordRequest(body);
      });
    }
  }
}
