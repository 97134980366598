import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { provideAuth, getAuth } from '@angular/fire/auth';

import { AuthRoutingModule } from './auth-routing.module';
import { CoreModule } from '../core/core.module';

// NgRx Store Module imports
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './+state/auth.effects';
import * as fromReducer from './+state/auth.reducer';

import {
  LoginComponent,
  LogoutPromptComponent,
  RequestPasswordResetComponent,
  AccountErrorComponent,
  PasswordResetComponent,
} from './components';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutPromptComponent,
    RequestPasswordResetComponent,
    AccountErrorComponent,
    PasswordResetComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', fromReducer.reducer),
    EffectsModule.forFeature([AuthEffects]),
    ReactiveFormsModule,
    CoreModule,
  ],
  exports: [AccountErrorComponent, PasswordResetComponent],
  providers: [provideAuth(() => getAuth())],
})
export class AuthModule {}
