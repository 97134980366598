/* src/app/auth/components/password-reset/password-reset.component.scss */
:host {
  display: flex;
  justify-content: center;
  margin: 100px 0px;
}
.mat-mdc-form-field {
  width: 100%;
  min-width: 300px;
}
.error {
  padding: 16px;
  width: 300px;
  color: white;
  background-color: red;
}
.header {
  display: flex;
  flex-direction: row;
  position: relative;
  top: -40px;
  height: 65px;
  align-self: center;
  align-content: center;
  border-radius: 5px;
  text-align: center;
}
.center-title {
  align-self: center;
  width: 100%;
}
mat-card-title {
  height: 40px;
}
.button {
  display: flex;
  justify-content: flex-end;
}
/*# sourceMappingURL=password-reset.component.css.map */
