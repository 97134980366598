import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  tap,
  filter,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import * as ConfigurationActions from './configuration.actions';
import { FirestoreService } from 'src/app/auth/services/firestore.service';
import { PortalConfiguration } from 'src/app/models';
import { Action } from '@ngrx/store';
import { LayoutFacade } from 'src/app/layout/+state';
import { AuthFacade } from 'src/app/auth/+state';
import { where } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth/services';
import { ErrorService } from 'src/app/core/services';

@Injectable()
export class ConfigurationEffects {
  constructor(
    private actions$: Actions,
    private firestoreUtils: FirestoreService,
    private layout: LayoutFacade,
    private auth: AuthFacade,
    private authService: AuthService,
    private error: ErrorService
  ) {}

  loadAdminOrgs$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<ConfigurationActions.LoadAdminConfigurationsAction>(
          ConfigurationActions.ActionTypes.LoadAdminConfigurations
        )
      ),
      this.auth.credentials$,
      this.auth.hasAdminRole$,
    ]).pipe(
      filter(([_, creds, hasAdmin]) => !!(creds && hasAdmin)),
      tap(() =>
        this.layout.setLoadingOn(
          ConfigurationActions.ActionTypes.LoadAdminConfigurations
        )
      ),
      switchMap(([action, credentials]) => {
        const roles = credentials.roles;
        const orgsWithAdmin = Object.keys(roles).filter((org) =>
          roles[org].includes('admin')
        );
        return this.firestoreUtils
          .col$<PortalConfiguration>(
            'configuration',
            where('id', 'in', orgsWithAdmin)
          )
          .pipe(
            takeUntil(
              this.authService.authState$.pipe(filter((auth) => !auth))
            ),
            map(
              (configurations) =>
                new ConfigurationActions.LoadAdminConfigurationsSuccessAction(
                  configurations
                )
            ),
            tap(() =>
              this.layout.setLoadingOff(
                ConfigurationActions.ActionTypes.LoadAdminConfigurations
              )
            ),
            catchError((err) => {
              this.layout.setLoadingOff(
                ConfigurationActions.ActionTypes.LoadAdminConfigurations
              );
              return of(
                new ConfigurationActions.LoadAdminConfigurationsErrorAction(err)
              );
            })
          );
      })
    );
  });

  loadAdminConfigurationsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConfigurationActions.LoadAdminConfigurationsErrorAction>(
          ConfigurationActions.ActionTypes.LoadAdminConfigurationsError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message = 'An error occurred retrieving admin configurations.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      ),
    { dispatch: false }
  );

  loadConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.ActionTypes.LoadConfigurations),
      switchMap(() =>
        this.firestoreUtils.col$<PortalConfiguration>('configuration').pipe(
          map((configurations) =>
            configurations.filter(
              (config): config is PortalConfiguration =>
                'name' in config && 'id' in config
            )
          ),
          map(
            (configurations) =>
              new ConfigurationActions.ConfigurationsLoadedAction(
                configurations
              )
          ),
          catchError((err) =>
            of(new ConfigurationActions.ConfigurationsLoadErrorAction(err))
          )
        )
      )
    )
  );

  clearState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfigurationActions.ActionTypes.ClearState),
        // Add any additional logic if needed
        map(() => {
          // Example: return a new action or perform a side effect
        })
      ),
    { dispatch: false }
  );
}
