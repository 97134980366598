import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './configuration.reducer';

export const selectConfigurationState =
  createFeatureSelector<fromReducer.State>('configurations');

export const selectLoaded = createSelector(
  selectConfigurationState,
  fromReducer.getLoaded
);

export const selectConfigurationIds = createSelector(
  selectConfigurationState,
  fromReducer.selectIds
);

export const selectConfigurationEntities = createSelector(
  selectConfigurationState,
  fromReducer.selectEntities
);

export const selectAllConfigurations = createSelector(
  selectConfigurationState,
  fromReducer.selectAll
);

export const selectAdminConfigurations = createSelector(
  selectConfigurationState,
  fromReducer.getAdminConfigurations
);

export const selectConfigurationTotal = createSelector(
  selectConfigurationState,
  fromReducer.selectTotal
);

export const selectConfigurationById = (id: string) =>
  createSelector(selectAllConfigurations, (configurations) =>
    configurations.find((config) => config.id === id)
  );
